<template>
  <div class="valine">
    <span>{{ $t("tellMe.description") }}</span>
  </div>
  </template>

<script>
// import valine from "@/components/valine/valine.vue"
export default {
   name:"tellMe",
   components:{
     //  valine
   }
}
</script>

<style scoped>
.valine{
  padding: 30px;
}
</style>